import React from 'react'
import './style.scss'
import {  HiOutlineDatabase, HiOutlineDeviceMobile, HiOutlineOfficeBuilding, HiOutlineTemplate, HiOutlineTerminal, HiOutlineUserGroup, HiOutlineViewGridAdd } from "react-icons/hi";
import Accordion from 'react-bootstrap/Accordion';

export default function Home() {
  return <main>
    <nav className="navbar navbar-expand-lg fixed-top">
      <div className="container">
        <a className="navbar-brand" href="#">cjdtech</a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav">
            <a className="nav-link active" aria-current="page" href="#">Início</a>
            <a className="nav-link" href="#">Soluções</a>
            <a className="nav-link" href="#">Serviços</a>
            <a className="nav-link" href="#">Contato</a>
          </div>
        </div>
        {/* <form className="d-flex" role="search">
          <input className="form-control me-2" type="search" placeholder="Pesquisar" aria-label="Pesquisar" />
          <button className="btn btn-outline-primary" type="submit">Buscar</button>
        </form> */}
      </div>
    </nav>
    <section>
      <div className="container banner d-flex align-items-center">
        <div className="d-flex">
          <div className='w-50'>
            <div className="container">
              <h1 className='display-4'><span className='text-gradient'>Inovação</span>  que <span className='text-gradient'>impulsiona</span>  o futuro, desenvolve talentos e transforma <span className='text-gradient'>negócios</span>.</h1>
              <p className="lead">Somos especialistas em soluções tecnológicas que impulsionam o crescimento do seu negócio.</p>
              <button type="button" className="btn btn-dark m-2">Comece seu projeto</button>
              <button type="button" className="btn btn-dark m-2">Agende uma consulta
              </button>
            </div>
          </div>
          <div className='w-50'>

          </div>
        </div>
      </div>

      <div className="beneficios">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <h2 className="display-5">Benefícios que <span className='text-gradient'>impulsionam</span>   seu <span className='text-gradient'>negócio</span></h2>
              <p>Transformamos e modernizamos empresas, por fornecer e capacitar com o que há de mais moderno em tecnologia de informação.</p>
            </div>
            <div className="col-md-7">
              <div className="row">
                <div className="col-lg-6 mb-4">
                  <div className="card">
                    <div className="card-body">
                      <HiOutlineTemplate className='icon' />
                      <h5 className="card-title">Soluções Inovadoras</h5>
                      <p className="card-text">Oferecemos soluções modernas e personalizadas para atender às suas necessidades específicas, utilizando as tecnologias mais avançadas do mercado.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mb-4">
                  <div className="card">
                    <div className="card-body">
                      <HiOutlineUserGroup className='icon' />
                      <h5 className="card-title">Nosso Diferencial</h5>
                      <p className="card-text">Contamos com uma equipe altamente qualificada e experiente, comprometida em oferecer um atendimento personalizado e de alta qualidade.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mb-4">
                  <div className="card">
                    <div className="card-body">
                      <HiOutlineViewGridAdd className='icon' />
                      <h5 className="card-title">Celeridade</h5>
                      <p className="card-text">Entregamos as soluções dentro dos prazos acordados, com agilidade e eficiência, para que você possa alcançar seus objetivos rapidamente.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mb-4">
                  <div className="card">
                    <div className="card-body">
                      <HiOutlineOfficeBuilding className='icon' />
                      <h5 className="card-title">Superação de Desafios</h5>
                      <p className="card-text">Ajudamos as empresas a superar os desafios da transformação digital, implementando soluções inovadoras e personalizadas que impulsionam o crescimento do negócio.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>


      <div className="visiao">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-4">
              <h2 className="display-5 ">Soluções únicas para o <span className='text-gradient'>sucesso</span>  do seu <span className='text-gradient'>negócio</span> </h2>
            </div>
            <div className="col-md-4">

              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0" className='accordion'>
                  <Accordion.Header className='border-0'><b>Nossa Missão</b></Accordion.Header>
                  <Accordion.Body>
                    Transformar e modernizar as empresas angolanas, por fornecer e capacitar no que há de mais moderno em tecnologia de informação.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className='accordion'>
                  <Accordion.Header className='border-0'><b>Nossa Visão</b></Accordion.Header>
                  <Accordion.Body>
                    Ser uma referência nacional no sector da tecnologia corporativa, bem como um catalisador do ecossistema de TI angolano.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className='accordion'>
                  <Accordion.Header className='border-0'><b>Nossos Valores</b></Accordion.Header>
                  <Accordion.Body>
                    Reconhecendo que a construção de uma identidade e cultura fortes é determinante para a concretização dos desafios a que se propõe, a cjdtech detém os seguintes valores:
                    Inovação, Assertividade, Espírito Pró-Activo, Integridade Intransigente
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              {/* <h3 className="card-title">Nossa Missão</h3>
              <p className="card-text">Transformar e modernizar as empresas angolanas, fornecendo e capacitando-as com o que há de mais moderno em tecnologia da informação.</p>
              <h3 className="card-title">Nossa Visão</h3>
              <p className="card-text">Ser referência nacional no setor da tecnologia corporativa e catalisador do ecossistema de TI angolano.</p>
           \
              <h3 className="card-title">Nossos Valores</h3>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">Inovação</li>
                <li className="list-group-item">Assertividade</li>
                <li className="list-group-item">Espírito Pró-Ativo</li>
                <li className="list-group-item">Integridade Intransigente</li>
              </ul> */}
            </div>

          </div>
        </div>
      </div>
      <div className="imgBg1"></div>
      <div className="servico ">
        <div className="container">
          <h1 className=" mb-5 pb-3 text-center"><span className='text-gradient'>Soluções</span>  sob medida <br /> <span className='text-gradient'>para</span>  o <span className='text-gradient'>seu negócio</span> </h1>
          <div className="row">
            <div className="col-md-4 mb-4">
              <div className="card">
                <div className="card-body">
                <HiOutlineTerminal className='icon'/>
                  <h5 className="card-title">Desenvolvimento de Software</h5>
                  <p className="card-text">Soluções sob medida utilizando as tecnologias mais avançadas do mercado, atendendo às suas necessidades específicas.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card">
                <div className="card-body">
                <HiOutlineDeviceMobile className='icon'/>
                  <h5 className="card-title">Desenvolvimento de Aplicativos</h5>
                  <p className="card-text">Criação de aplicativos Android e iOS intuitivos e personalizados, impulsionando a experiência dos seus usuários.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card">
                <div className="card-body">
                <HiOutlineDatabase className='icon'/>
                  <h5 className="card-title">Hospedagem de Sites</h5>
                  <p className="card-text">Planos confiáveis e escaláveis de hospedagem, garantindo alta disponibilidade, segurança e rapidez para o seu site.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="imgBg1"></div>
      <div className='chats'>
        <div className="container">
        <div className="row justify-content-between">
          <div className="col-md-4 mb-3">
          <p className='opacity-50'>CONSULTORIA GRATUÍTA</p>
          <h1 className='display-5'>
          Tem um projecto?
          Fale connosco.
          </h1>
          Agendar uma reunião virtual
          Preencha o formulário, que um de nossos especialistas entrará em contacto consigo dentro de 24h.
          </div>
          <div className="col-md-4 mb-3 d-flex align-items-center">
            <div>
              <button className='btn'> Fala concos pelo whasapp</button>
            </div>
          </div>
        </div>
        </div>
      </div>
    </section>
    <footer className="footer pt-5">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <h3 className="footer-title">cjdtech</h3>
            <p className="footer-text">Somos uma empresa angolana com o objetivo de transformar e modernizar empresas, fornecendo e capacitando com o que há de mais moderno em tecnologia da informação.</p>
          </div>
          <div className="col-md-4">
            <h5 className="footer-title">Nossas Soluções</h5>
            <ul className="list-unstyled">
              <li><a href="#">Desenvolvimento de Aplicações</a></li>
              <li><a href="#">Infraestrutura Empresarial</a></li>
              <li><a href="#">Infraestrutura Cloud</a></li>
            </ul>
          </div>
          <div className="col-md-4">
            <h5 className="footer-title">Contacte-nos</h5>
            <p className="footer-text">Interessado em nossos serviços?</p>
            <ul className="list-unstyled">
              <li><a href="mailto:vendas@pixel.ao">geral@....ao</a></li>
              <li><a href="tel:+244943301150">(+...) ... ... ...</a></li>
            </ul>
          </div>
        </div>
      </div>
       <div className="pt-5 pb-2 container opacity-50">
          <span className="footer-copyright">&copy; 2024 cjdtech. Todos os direitos reservados.</span>
        </div>
    </footer>
  </main>
}

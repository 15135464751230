import React from 'react';
import logo from './logo.svg';
import './App.css';
import Home from './pages/home';

function App() {
  return <Home/>
}

export default App;
